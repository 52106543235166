import React, { useEffect, useState } from "react";
import "./Services.css";
import { motion, AnimatePresence } from "framer-motion";
import { useInView } from "react-intersection-observer";
import pic2 from "../Imgs/Work/pic2.png";
import pic4 from "../Imgs/Work/pic4.png";
import pic8 from "../Imgs/Work/pic8.png";
import pic15 from "../Imgs/Work/pic15.png";
import pic16 from "../Imgs/Work/pic16.png";
import pic17 from "../Imgs/Work/pic17.png";
import pic18 from "../Imgs/Work/pic18.png";
import pic19 from "../Imgs/Work/pic19.png";
import pic20 from "../Imgs/Work/pic20.png";
import pic21 from "../Imgs/Work/pic21.png";
import pic22 from "../Imgs/Work/pic22.png";
import pic23 from "../Imgs/Work/pic23.png";
import pic24 from "../Imgs/Work/pic24.png";

const Services = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const handleResize = () => setIsLargeScreen(window.innerWidth > 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const services = [
    {
      title: "Offices Fit-Out",
      description:
        "Our experienced team ensures every aspect of the office fit-out is meticulously planned and executed to maximize efficiency and enhance employee well-being.",
      image: pic18,
      icon: "🏢",
    },
    {
      title: "Entertainment Fit-Out",
      description:
        "We specialize in creating captivating and immersive entertainment spaces through our expert fit-out services, combining innovative design and cutting-edge technology.",
      image: pic20,
      icon: "🎭",
    },
    {
      title: "Retail Fit-Out",
      description:
        "Our retail fit-out services revitalize and enhance retail spaces, combining aesthetic appeal, strategic layout, and customer-friendly elements to create an inviting and engaging shopping experience.",
      image: pic2,
      icon: "🛍️",
    },
    {
      title: "Mall Fit-Out",
      description:
        "Our expert mall fit-out services ensure an exceptional shopping experience by optimizing layout, design, and functionality with a perfect blend of aesthetics and convenience.",
      image: pic22,
      icon: "🏬",
    },
    {
      title: "Marble Supply and Installation",
      description:
        "Elevate your marble trading endeavors with our fit-out services, emphasizing elegance and exceptional quality in every supply and installation project.",
      image: pic19,
      icon: "💎",
    },
    {
      title: "General Maintenance",
      description:
        "Our maintenance services of buildings prioritize efficiency and quality, ensuring timely and expert maintenance solutions to keep your spaces in optimal condition.",
      image: pic8,
      icon: "🔧",
    },
  ];

  const ProjectModal = ({ image, title, onClose }) => {
    return (
      <motion.div
        className="modal-backdrop"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={onClose}
      >
        <motion.div
          className="modal-content"
          initial={{ scale: 0.5, y: 100 }}
          animate={{
            scale: 1,
            y: 0,
            transition: {
              type: "spring",
              stiffness: 300,
              damping: 30,
            },
          }}
          exit={{
            scale: 0.5,
            y: 100,
            transition: { duration: 0.3 },
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <motion.img
            src={image}
            alt={title}
            className="modal-image"
            layoutId={`project-image-${title}`}
          />
          <motion.div
            className="modal-details"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            <h3>{title}</h3>
            <motion.button
              className="close-button"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={onClose}
            >
              ×
            </motion.button>
          </motion.div>
        </motion.div>
      </motion.div>
    );
  };

  const ServiceCard = ({ service, index }) => {
    const [ref, inView] = useInView({
      threshold: 0.2,
      triggerOnce: true,
    });

    const cardVariants = {
      hidden: {
        opacity: 0,
        y: 50,
        scale: 0.9,
      },
      visible: {
        opacity: 1,
        y: 0,
        scale: 1,
        transition: {
          duration: 0.6,
          delay: index * 0.2,
        },
      },
    };

    return (
      <motion.div
        ref={ref}
        className="service-card"
        variants={cardVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
      >
        <div className="service-content">
          <motion.span
            className="service-icon"
            whileHover={{ scale: 1.2, rotate: 360 }}
            transition={{ duration: 0.5 }}
          >
            {service.icon}
          </motion.span>
          <motion.h3 className="service-title" whileHover={{ scale: 1.05 }}>
            {service.title}
          </motion.h3>
          <p className="service-description">{service.description}</p>
        </div>
        <motion.div
          className="service-image-container"
          whileHover={{ scale: 1.02 }}
          layoutId={`project-image-${service.title}`}
        >
          <img
            src={service.image}
            alt={service.title}
            className="service-image"
          />
          <motion.div
            className="image-overlay"
            whileHover={{ opacity: 1 }}
            onClick={() => setSelectedImage(service)}
          >
            <motion.span
              className="view-project"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              View Project
            </motion.span>
          </motion.div>
        </motion.div>
      </motion.div>
    );
  };

  return (
    <motion.section
      className="services-section"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <motion.div
        className="services-header"
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <h1 className="services-title">Services and Core Competencies</h1>
        <div className="title-underline"></div>
      </motion.div>

      <div className="services-grid">
        {services.map((service, index) => (
          <ServiceCard key={index} service={service} index={index} />
        ))}
      </div>

      <AnimatePresence>
        {selectedImage && (
          <ProjectModal
            image={selectedImage.image}
            title={selectedImage.title}
            onClose={() => setSelectedImage(null)}
          />
        )}
      </AnimatePresence>
    </motion.section>
  );
};

export default Services;
