import React from "react";
import Slider from "react-slick";
import { motion } from "framer-motion";
import "./TrustedBy.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TrustedBy = () => {
  const companies = [
    {
      name: "Emaar",
      logo: "https://cdn.emaar.com/wp-content/themes/twentytwenty-child/assets/images/emaar-logo.svg",
      customClass: "Emaar",
    },
    {
      name: "Farnek",
      logo: "https://www.farnek.com/wp-content/uploads/2020/10/Farnek-logo-orange.png",
      customClass: "farnek-logo",
    },
    {
      name: "DubaiMall",
      logo: "https://m.eyeofriyadh.com/news_images/2019/03/2cd369942c1f2.png",
      customClass: "DubaiMall",
    },
    {
      name: "Enova",
      logo: "https://pbs.twimg.com/profile_images/1105051637427683328/udLetBdn_400x400.png",
      customClass: "Enova",
    },
    {
      name: "AlFuttaim",
      logo: "https://sab-us.com/web/image/res.partner/2668/image?unique=71f30dc",
      customClass: "alfuttaim-logo",
    },
    {
      name: "Emrill",
      logo: "https://www.emrill.com/wp-content/uploads/2019/07/Emrill.png",
      customClass: "Emrill",
    },
  ];

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    arrows: false,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1156,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <motion.section
      className="trusted-by"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      viewport={{ once: true }}
    >
      <motion.div
        className="trusted-header"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.2 }}
        viewport={{ once: true }}
      >
        <h2>Trusted By Industry Leaders</h2>
        <p className="trusted-subtitle">
          Partnering with the region's most prestigious names
        </p>
      </motion.div>

      <motion.div
        className="companies-wrapper"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.4 }}
        viewport={{ once: true }}
      >
        <div className="companies mobile-carousel">
          <Slider {...sliderSettings}>
            {companies.map((company, index) => (
              <motion.div
                key={index}
                className="logo-container2"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              >
                <img
                  src={company.logo}
                  alt={company.name}
                  className={`company-logo ${company.customClass || ""}`}
                />
              </motion.div>
            ))}
          </Slider>
        </div>
      </motion.div>
    </motion.section>
  );
};

export default TrustedBy;
