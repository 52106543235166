import React, { useState, useEffect, useRef } from "react";
import emailjs from "emailjs-com";
import logo from "../Imgs/logo.png";
import pic1 from "../Imgs/Work/pic1.png";
import "./Header.css";
import { ImWhatsapp } from "react-icons/im";
import { RxDoubleArrowDown } from "react-icons/rx";
import {
  motion,
  AnimatePresence,
  useScroll,
  useTransform,
} from "framer-motion";
import { FiUser, FiMail, FiPhone, FiMessageSquare } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const emptyForm = {
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  };
  const [showArrow, setShowArrow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({ ...emptyForm });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { scrollYProgress } = useScroll();
  const scale = useTransform(scrollYProgress, [0, 1], [1, 1.2]);
  const opacity = useTransform(scrollYProgress, [0, 0.5], [1, 0]);
  const y = useTransform(scrollYProgress, [0, 1], [0, -100]);
  const contactFormRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkScroll = () => {
      if (window.pageYOffset > 10) {
        setShowArrow(false);
      } else {
        setShowArrow(true);
      }
    };
    window.addEventListener("scroll", checkScroll);
    return () => window.removeEventListener("scroll", checkScroll);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm(
        "service_81v2sbr",
        "template_kmmmyea",
        e.target,
        "gYX9isnWXQOYiELzC"
      )
      .then(
        (result) => {
          setForm({ ...emptyForm });
          setFormSubmitted(true);
          setLoading(false);
          setTimeout(() => setFormSubmitted(false), 5000);
        },
        (error) => {
          console.log("Form submission error", error.text);
          setLoading(false);
        }
      );
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleWhatsappClick = () => {
    window.open("https://wa.me/00971504419960", "_blank");
  };

  const scrollToContact = () => {
    contactFormRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="header-container">
      <div className="hero-section">
        <motion.div className="image-container" style={{ scale }}>
          <img src={pic1} alt="Hero" className="hero-image" />
          <div className="image-overlay"></div>
        </motion.div>

        <motion.div className="hero-content desktop-only">
          <motion.div className="hero-text-container">
            <motion.h1 className="main-title">
              Crafting Excellence in
              <motion.span className="highlight-text">Every Space</motion.span>
            </motion.h1>
            <motion.p className="subtitle">
              Transform your vision into reality with our premium fit-out
              solutions
            </motion.p>
          </motion.div>
        </motion.div>

        <motion.div
          className="mobile-content"
          style={{ opacity, y }}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <motion.div
            className="floating-shape shape1"
            animate={{
              y: [0, -20, 0],
              rotate: [0, 360],
              scale: [1, 1.1, 1],
            }}
            transition={{
              duration: 8,
              repeat: Infinity,
              ease: "linear",
            }}
          />
          <motion.div
            className="floating-shape shape2"
            animate={{
              x: [0, 20, 0],
              rotate: [0, -360],
              scale: [1, 1.2, 1],
            }}
            transition={{
              duration: 10,
              repeat: Infinity,
              ease: "linear",
            }}
          />

          <motion.img
            src={logo}
            alt="Logo"
            className="mobile-logo"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 200,
              delay: 0.5,
            }}
          />

          <motion.div
            className="hero-text-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.8 }}
          >
            <motion.h1
              className="main-title"
              initial={{ x: -100 }}
              animate={{ x: 0 }}
              transition={{ type: "spring", delay: 1 }}
            >
              Crafting Excellence in
              <motion.span
                className="highlight-text"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 1.5 }}
              >
                Every Space
              </motion.span>
            </motion.h1>

            <motion.p
              className="subtitle"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 2 }}
            >
              Transform your vision into reality
            </motion.p>

            <motion.div
              className="cta-container"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 2.5 }}
            >
              <motion.button
                className="cta-button"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => navigate("/contact")}
              >
                Let's Connect
              </motion.button>
            </motion.div>
          </motion.div>
        </motion.div>

        <motion.div
          className="contact-section desktop-only"
          ref={contactFormRef}
        >
          <div className="contact-header">
            <motion.img
              src={logo}
              alt="Logo"
              className="company-logo"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            />
            <motion.h2
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1 }}
            >
              Start Your Project
            </motion.h2>
          </div>

          <AnimatePresence>
            {formSubmitted ? (
              <motion.div
                className="success-message"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
              >
                <div className="success-content">
                  <div className="success-icon">✓</div>
                  <h3>Thank You!</h3>
                  <p>We'll be in touch with you shortly.</p>
                </div>
              </motion.div>
            ) : (
              <motion.form className="contact-form" onSubmit={handleSubmit}>
                <div className="input-group">
                  <input
                    type="text"
                    name="name"
                    placeholder="Your Name"
                    value={form.name}
                    onChange={handleChange}
                    required
                  />
                  <span className="input-icon">
                    <FiUser />
                  </span>
                </div>

                <div className="input-group">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    value={form.email}
                    onChange={handleChange}
                    required
                  />
                  <span className="input-icon">
                    <FiMail />
                  </span>
                </div>

                <div className="input-group">
                  <input
                    type="tel"
                    name="phone"
                    placeholder="Phone Number"
                    value={form.phone}
                    onChange={handleChange}
                    required
                  />
                  <span className="input-icon">
                    <FiPhone />
                  </span>
                </div>

                <div className="input-group">
                  <textarea
                    name="message"
                    placeholder="Your Message"
                    value={form.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                  <span className="input-icon textarea-icon">
                    <FiMessageSquare />
                  </span>
                </div>

                <motion.button
                  type="submit"
                  className="submit-button"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  disabled={loading}
                >
                  {loading ? (
                    <span className="loading-spinner"></span>
                  ) : (
                    "Let's Connect"
                  )}
                </motion.button>
              </motion.form>
            )}
          </AnimatePresence>
        </motion.div>
      </div>

      <motion.div
        className="whatsapp-button"
        onClick={handleWhatsappClick}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        <ImWhatsapp />
      </motion.div>

      <AnimatePresence>
        {showArrow && (
          <motion.div
            className="scroll-indicator"
            initial={{ opacity: 0, y: -20 }}
            animate={{
              opacity: [0.4, 1, 0.4],
              y: [0, 10, 0],
            }}
            transition={{
              repeat: Infinity,
              duration: 2,
            }}
            exit={{ opacity: 0, y: 20 }}
          >
            <RxDoubleArrowDown />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Header;
