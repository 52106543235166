// Projects.js
import React, { useState } from "react";
import Slider from "react-slick";
import { motion, AnimatePresence } from "framer-motion";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Projects.css";
import pic1 from "../Imgs/Work/pic1.png";
import pic2 from "../Imgs/Work/pic2.png";
import pic3 from "../Imgs/Work/pic3.png";
import pic4 from "../Imgs/Work/pic4.png";
import pic5 from "../Imgs/Work/pic5.png";
import pic6 from "../Imgs/Work/pic6.png";
import pic7 from "../Imgs/Work/pic7.png";
import pic8 from "../Imgs/Work/pic8.png";
import pic9 from "../Imgs/Work/pic9.png";
import pic10 from "../Imgs/Work/pic10.png";
import pic11 from "../Imgs/Work/pic11.png";
import pic12 from "../Imgs/Work/pic12.png";
import pic13 from "../Imgs/Work/pic13.png";
import pic14 from "../Imgs/Work/pic14.png";
import pic15 from "../Imgs/Work/pic15.png";
import pic16 from "../Imgs/Work/pic16.png";
import pic17 from "../Imgs/Work/pic17.png";
import pic21 from "../Imgs/Work/pic21.png";
import pic23 from "../Imgs/Work/pic23.png";
import pic24 from "../Imgs/Work/pic24.png";
import pic25 from "../Imgs/Work/pic25.png";
import pic26 from "../Imgs/Work/pic26.png";

const Projects = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const projectImages = [
    pic1,
    pic4,
    pic5,
    pic6,
    pic7,
    pic8,
    pic9,
    pic10,
    pic11,
    pic12,
    pic13,
    pic15,
    pic16,
    pic17,
    pic21,
    pic23,
    pic24,
    pic25,
    pic26,
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "cubic-bezier(0.4, 0, 0.2, 1)",
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <motion.section
      className="projects"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
      viewport={{ once: true }}
    >
      <motion.div
        className="projects-header"
        initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.6 }}
        viewport={{ once: true }}
      >
        <h2>Transforming Spaces</h2>
        <p className="projects-subtitle">
          Discover our portfolio of exceptional fit-out projects
        </p>
      </motion.div>

      <div className="projects-slider">
        <Slider {...settings}>
          {projectImages.map((image, index) => (
            <motion.div
              key={index}
              className="project-slide"
              whileHover={{ scale: 1.02 }}
              transition={{ duration: 0.3 }}
            >
              <div className="project-card">
                <img
                  src={image}
                  alt={`Project ${index + 1}`}
                  className="project-image"
                />
                <div
                  className="project-overlay"
                  onClick={() => setSelectedImage(image)}
                >
                  <span>View Project</span>
                </div>
              </div>
            </motion.div>
          ))}
        </Slider>
      </div>

      <AnimatePresence>
        {selectedImage && (
          <motion.div
            className="image-modal-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setSelectedImage(null)}
          >
            <motion.div
              className="image-modal"
              initial={{ scale: 0.5 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.5 }}
            >
              <img src={selectedImage} alt="Project Preview" />
              <button
                className="close-button"
                onClick={() => setSelectedImage(null)}
              >
                ×
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="separator2" />
    </motion.section>
  );
};

export default Projects;
