import React, { useEffect, useRef } from "react";
import "./AboutUs.css";
import { motion, useScroll, useTransform } from "framer-motion";

const AboutUs = () => {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start end", "end start"],
  });

  const words = [
    "Innovation",
    "Quality",
    "Excellence",
    "Integrity",
    "Commitment",
  ];

  return (
    <motion.section
      ref={containerRef}
      className="about-us-container"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
    >
      <div className="about-us-content">
        <motion.div
          className="floating-words"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          {words.map((word, index) => (
            <motion.span
              key={word}
              className="floating-word"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.2 }}
            >
              {word}
            </motion.span>
          ))}
        </motion.div>

        <motion.div
          className="about-text-container"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <motion.h2
            className="about-title"
            initial={{ x: -100 }}
            whileInView={{ x: 0 }}
            transition={{ type: "spring", stiffness: 100 }}
          >
            About Us
          </motion.h2>

          <div className="text-columns">
            <motion.p
              className="about-paragraph"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              <span className="highlight">Fit-Out Co.</span> is a UAE-based
              leader in fit-out and maintenance with over 15 years of
              experience. We've completed notable projects like a 60,000 sqm
              tiling installation in Sharjah Free Zone.
            </motion.p>

            <motion.p
              className="about-paragraph"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              Our reputation is built on quality workmanship, ethical practices,
              and adaptability. We strive to exceed client expectations, align
              with project objectives, and build lasting relationships.
            </motion.p>
          </div>
        </motion.div>

        <motion.div
          className="stats-container"
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.7 }}
        >
          <motion.div className="stat-item" whileHover={{ scale: 1.1 }}>
            <span className="stat-number">15+</span>
            <span className="stat-label">Years Experience</span>
          </motion.div>
          <motion.div className="stat-item" whileHover={{ scale: 1.1 }}>
            <span className="stat-number">200+</span>
            <span className="stat-label">Projects Completed</span>
          </motion.div>
          <motion.div className="stat-item" whileHover={{ scale: 1.1 }}>
            <span className="stat-number">100%</span>
            <span className="stat-label">Client Satisfaction</span>
          </motion.div>
        </motion.div>

        <motion.div
          className="geometric-shapes"
          style={{ opacity: useTransform(scrollYProgress, [0, 0.5], [0, 1]) }}
        >
          <div className="shape shape1"></div>
          <div className="shape shape2"></div>
          <div className="shape shape3"></div>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default AboutUs;
