// Footer.js
import React from "react";
import { Link } from "react-router-dom"; // Import Link
import logo from "../Imgs/logo.png"; // Import the logo
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <Link to="/">
          <img src={logo} alt="AlSuhool Logo" className="footer-logo" />
        </Link>
        <div className="footer-contact">
          <a href="tel:+971504419960">+971 50 441 9960</a>
          <span className="divider">|</span>
          <a href="mailto:info@alsuhool.com">info@alsuhool.com</a>
        </div>
        <div className="footer-copyright">© 2025 AlSuhool</div>
      </div>
    </footer>
  );
};

export default Footer;
